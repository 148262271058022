import axios from "axios";
const AccountAPI = process.env.REACT_APP_BASIC_API + "/api/Account";

function bytesToBase64(bytes) {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
}

function base64EncodingForSignup(email, pKey, pKeyConfirm) {
    const emailEncoded = bytesToBase64(new TextEncoder().encode(email));
    const pKeyEncoded = bytesToBase64(new TextEncoder().encode(pKey));
    const pKeyConfirmEncoded = bytesToBase64(new TextEncoder().encode(pKeyConfirm));
    return { email: emailEncoded, pKey: pKeyEncoded, pKeyConfirm: pKeyConfirmEncoded };
}

function base64EncodingForLogin(email, abcd) {
    const emailEncoded = bytesToBase64(new TextEncoder().encode(email));
    const abcdEncoded = bytesToBase64(new TextEncoder().encode(abcd));
    return { email: emailEncoded, abcd: abcdEncoded };
}

class authService {
    // 註冊
    memberSignup(req) {
        const { email, pKey, pKeyConfirm } = base64EncodingForSignup(req.email, req.pKey, req.pKeyConfirm);
        return axios.post(`${AccountAPI}/Register?email=${email}&pKey=${pKey}&pKeyConfirm=${pKeyConfirm}&role=Member`);
    }

    // 註冊後，驗證從 url 截取到的 token 是否跟後端的資料一致
    verifySignupToken(token) {
        token = token.replace("&", "%26");
        return axios.post(`${AccountAPI}/VerifyMail`, {
            token
        });
    }

    // 註冊後驗證信的 token 過期，重發驗證信
    resendVerificationCode(email) {
        return axios.post(`${AccountAPI}/SendVerifyMail`, {
            email
        });
    }

    login(req) {
        const { email, abcd } = base64EncodingForLogin(req.email, req.abcd);
        return axios.post(`${AccountAPI}/Login?email=${email}&abcd=${abcd}`);
    }

    logout() {
        localStorage.removeItem("nbrp_client_user_data");
    }

    getCurrentUser() {
        let token;
        JSON.parse(localStorage.getItem("nbrp_client_user_data"))
        ? token = JSON.parse(localStorage.getItem("nbrp_client_user_data")).token
        : token = "";
        // console.log(token)

        return axios.get(
            `${AccountAPI}`, {
                headers: {"Authorization": `Bearer ${token}`}
            }
        );
        // return JSON.parse(localStorage.getItem("nbrp_client_user_data"));
    }

    forgetPassword(email) {
        return axios.post(`${AccountAPI}/ForgetPassword`, {
            email
        });
    }

    resetPassword(req) {
        let { token, newPKey, newPKeyConfirm } = req;
        token = token.replace("&", "%26");
        return axios.post(`${AccountAPI}/ResetPassword`, {
            token,
            newPKey,
            newPKeyConfirm
        });
    }

    updateUserInfo(req) {
        let token;
        JSON.parse(localStorage.getItem("nbrp_client_user_data"))
        ? token = JSON.parse(localStorage.getItem("nbrp_client_user_data")).token
        : token = "";
        let { name, password, confirmedPassword } = req;
        // 一定要加 req.body 才會成功
        return axios.post(
            `${AccountAPI}/Update?newPassword=${password}&newPasswordConfirm=${confirmedPassword}&name=${name}`, {}, {
                headers: {"Authorization": `Bearer ${token}`}
            }
        );
    }
}

const NewAuthService = new authService();
export default NewAuthService;