import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";

import NewEventService from "../service/event-service";

const API = process.env.REACT_APP_BASIC_API;

const Homepage = () => {
    const Navigate = useNavigate();
    const [ bannerPC, setBannerPC ] = useState("");
    const [ bannerPad, setBannerPad ] = useState("");
    const [ bannerPhone, setBannerPhone ] = useState("");
    const [ registrationButtonIsEnabled, setRegistrationButtonIsEnabled ] = useState(false);

    const handleSignUp = (e) => {
        if (localStorage.getItem("nbrp_client_user_data")) {
            Navigate("/register");
        } else {
            Navigate("/login");
        }
    }

    const handleLinkToMIT = () => {
        window.open('https://www.accupass.com/event/2403280844294800916130', '_blank')
    }

    const handleOpenOnAirLink = () => window.open('https://youtube.com/live/BbfTZaUJv9o?feature=share', '_blank');

    useEffect(() => {
        NewEventService.getCurrent()
        .then((d) => {
            const { homepageHeaderImagePC, homepageHeaderImagePad, homepageHeaderImagePhone, registrationButtonIsEnabled } = d.data;
            setBannerPC(homepageHeaderImagePC);
            setBannerPad(homepageHeaderImagePad);
            setBannerPhone(homepageHeaderImagePhone);
            setRegistrationButtonIsEnabled(registrationButtonIsEnabled);
        })
        .catch((e) => {
            console.log(e);
        })
    }, []);

    return (
        <>
            <main className="banner position-relative w-100 d-none d-lg-flex flex-column align-items-center justify-content-end" style={{background: `url(${API}${bannerPC}) center/cover no-repeat`, minHeight: "800px", marginTop: "-60px"}}>
                <button type="button" className="btn py-2 px-4 rounded-3 fs-4 border-0 mb-10" style={{background: "linear-gradient(to right, #f4db16, #78d687)"}} onClick={handleOpenOnAirLink}>Demo Day On Air</button>
                <div className="d-flex mb-10">
                    <button type="button" className="btn py-2 px-4 rounded-3 fs-4 border-0" style={{background: "#00ffff"}} disabled={!registrationButtonIsEnabled} onClick={handleSignUp}>{registrationButtonIsEnabled ? "Registration" : "Registration has ended"}</button>
                    <button type="button" className="btn ms-8 py-2 px-4 rounded-3 fs-4 text-white border-0" style={{backgroundImage: "linear-gradient(to right, #b6161b, #f944df)"}} onClick={handleLinkToMIT}>MIT Masterclass</button>
                </div>
            </main>

            <main className="banner mdBanner position-relative w-100 d-lg-none align-items-end" style={{background: `url(${API}${bannerPad}) center 40%/cover no-repeat`, minHeight: "800px"}}>
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn py-2 px-4 rounded-3 fs-3 border-0 mb-10" style={{background: "linear-gradient(to right, #f4db16, #78d687)"}} onClick={handleOpenOnAirLink}>Demo Day On Air</button>
                    </div>
                    <div className="d-flex mb-20 buttons">
                        <button type="button" className="btn py-2 px-4 rounded-3 fs-3 border-0" style={{background: "#00ffff"}} disabled={!registrationButtonIsEnabled} onClick={handleSignUp}>{registrationButtonIsEnabled ? "Registration" : "Registration has ended"}</button>
                        <button type="button" className="btn ms-8 py-2 px-4 rounded-3 fs-3 text-white border-0" style={{backgroundImage: "linear-gradient(to right, #b6161b, #f944df)"}} onClick={handleLinkToMIT}>MIT Masterclass</button>
                    </div>
                </div>
            </main>

            <main className="banner smBanner position-relative w-100 align-items-end position-relative z-3" style={{background: `url(${API}${bannerPhone}) center 20%/cover no-repeat`, minHeight: "667px"}}>
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn py-2 rounded-3 fs-3 border-0 onAirButtonXs" style={{background: "linear-gradient(to right, #f4db16, #78d687)"}} onClick={handleOpenOnAirLink}>Demo Day On Air</button>
                    </div>
                    <div className="d-flex mb-20 buttons">
                        <button type="button" className="btn py-2 px-4 rounded-3 fs-2 border-0" style={{background: "#00ffff"}} disabled={!registrationButtonIsEnabled} onClick={handleSignUp}>{registrationButtonIsEnabled ? "Registration" : "Registration has ended"}</button>
                        <button type="button" className="btn ms-8 py-2 px-4 rounded-3 fs-2 text-white border-0" style={{backgroundImage: "linear-gradient(to right, #b6161b, #f944df)"}} onClick={handleLinkToMIT}>MIT Masterclass</button>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Homepage;