import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import NewAuthService from "../service/auth-service";
import NewEventService from "../service/event-service";
import Swal from "sweetalert2";

import bg from "../assets/images/footer/資產 13.png";

const API = process.env.REACT_APP_BASIC_API;

const Nav = ({ currentUser, setCurrentUser, programs, logo, isMenuOpen, isPosterExhibitionRegistration, setIsPosterExhibitionRegistration }) => {
    const Navigate = useNavigate();

    let programsList;
    let accountList;
    
    // 登出
    const handleLogout = (e) => {
        setIsPosterExhibitionRegistration(false)
        NewAuthService.logout();
        localStorage.clear();
        setCurrentUser(null);
        accountList = document.getElementById("accountList");
        programsList = document.getElementById("programsList");
        accountList.classList.add("d-none");
        programsList.classList.add("d-none");
        handleGoToOtherPages(e); // 不直接用 Navigate 是因為還要關閉 offcanvas
    }

    // 不直接用 Link 是因為還要關閉 offcanvas
    const handleGoToOtherPages = (e) => {
        handleCloseList();
        let btn = document.querySelector(".btn-close");
        switch (e.target.innerText) {
            case "Home":
                Navigate("/");
                break;
            case "About":
                Navigate("/about");
                break;
            case "Programs":
                Navigate("/programs");
                break;
            case "News":
                Navigate("/news");
                break;
            case "Agenda":
                Navigate("/agenda");
                break;
            case "Speakers":
                Navigate("/speakers");
                break;
            case "Traffic":
                Navigate("/traffic-info");
                break;
            case "Sign Up":
                Navigate("/signup");
                break;               
            case "Account":
                Navigate("/account");
                break;
            case "Log In":
                Navigate("/login");
                break;
            case "Register":
                Navigate("/register");
                break;
            case "Log Out":
                Navigate("/");
                break;    
        }
        btn.click();
        window.scrollTo({ // 這個是專門因應「登出前就已經在 Homepage」的情形
            top: "0",
            behavior: "instant",
        });
    }

    const handleSignUp = () => {
        if (localStorage.getItem("event")) {
            if (!JSON.parse(localStorage.getItem("event")).registrationButtonIsEnabled) {
                Swal.fire({
                    title: "報名已截止",
                    text: "This event is closed for registration.",
                    icon: "info",
                    confirmButtonText: "OK",
                });
                Navigate("/");
            } else {
                Navigate("/signup");
            }
        }
        handleCloseList();
    }

    const handleSignUpMobile = (e) => {
        if (JSON.parse(localStorage.getItem("event"))) {
            if (!JSON.parse(localStorage.getItem("event")).registrationButtonIsEnabled) {
                Swal.fire({
                    title: "報名已截止",
                    text: "This event is closed for registration.",
                    icon: "info",
                    confirmButtonText: "OK",
                })
            } else {
                Navigate("/signup");
            }
            handleGoToOtherPages(e);    
        }
    }


    const handleToggleProgramsList = () => {
        programsList = document.getElementById("programsList");
        let programsListOffcanvas = document.getElementById("programsListOffcanvas");
        accountList = document.getElementById("accountList");
        // console.log(programsList, accountList)
        programsList.classList.toggle("d-none");
        programsListOffcanvas.classList.toggle("d-none");
        if (accountList) {
            accountList.classList.add("d-none");
            accountList.classList.remove("d-flex");
        }
    }

    const handleToggleAccountList = () => {
        accountList = document.getElementById("accountList");
        programsList = document.getElementById("programsList");
        accountList.classList.toggle("d-none");
        let accountListOffcanvas = document.getElementById("accountListOffcanvas");
        accountListOffcanvas.classList.toggle("d-none");
        if (programsList) {
            programsList.classList.add("d-none");
            programsList.classList.remove("d-flex");
        }
    }

    const handleCloseList = () => {
        accountList = document.getElementById("accountList");
        programsList = document.getElementById("programsList");
        let accountListOffcanvas = document.getElementById("accountListOffcanvas");
        let programsListOffcanvas = document.getElementById("programsListOffcanvas");
        if (accountList) {
            accountList.classList.add("d-none");
            accountList.classList.remove("d-flex");
        }
        if (programsList) {
            programsList.classList.add("d-none");
            programsList.classList.remove("d-flex");
        }
        if (accountListOffcanvas) {
            accountListOffcanvas.classList.add("d-none");
        }
        if (programsListOffcanvas) {
            programsListOffcanvas.classList.add("d-none");
            programsListOffcanvas.classList.remove("d-flex");
        }
    }

    const handleCloseListAndOffcanvas = (e) => {
        e.stopPropagation();
        handleCloseList();
        let offcanvasBtn = document.querySelector("#offcanvasBtn");
        offcanvasBtn.click();
    }

    return (
        <section className="position-sticky top-0" style={{color: "rgb(22, 61, 127)", height: "10vh", zIndex: "10", background: `url(${bg}) center/cover no-repeat`}}>

            <nav className="navbar navbar-expand d-none d-lg-block p-0 h-100">
                <div className="container d-flex justify-content-between py-3 h-100">
                    <h1 className="d-flex align-items-center">
                        <Link to="/" className="d-flex align-items-end d-block" style={{background: `url(${API}${logo}) center/contain no-repeat`, width: "124px", height: "45px", textIndent: "101%", overflow: "hidden", whiteSpace: "nowrap"}} onClick={handleCloseList}></Link>
                    </h1>
                    <ul className="navbar-nav hoverBgDarkUl list-unstyled">
                        <li>
                            <Link className="nav-link rounded-0" to="/" onClick={handleCloseList}>Home</Link>
                        </li>
                        <li>
                            <Link className="nav-link rounded-0" to="/about" onClick={handleCloseList}>About</Link>
                        </li>                       
                        <li className="d-flex align-items-center nav-link position-relative">
                            <div className="cursor-pointer" onClick={handleToggleProgramsList}>
                                <div className="d-flex">
                                    <p id="programsListP">Programs</p>
                                    <span className="material-symbols-outlined fs-2 align-self-end" id="programsListSpan">
                                        expand_more
                                    </span>
                                </div>
                                <ul id="programsList" className="hoverBgDarkUl d-none d-flex flex-column flex-wrap position-absolute bg-white p-2 sessionSubList mt-2" style={{ minWidth: "300%", border: "1px solid rgb(22, 61, 127)", marginLeft: "-0.5rem"}}>
                                    {programs.length > 0 && programs.map((i) =>
                                    <li className="d-flex" key={i.id}>
                                        <Link to={`/programs/${i.id}`} className="py-2 px-3 rounded-0 w-100" onClick={handleCloseList}>{i.title}</Link>
                                    </li>
                                    )}
                                    <li className="d-flex" key={"talent-matching"}>
                                        <Link to={`/talent-matching`} className="py-2 px-3 rounded-0 w-100" onClick={handleCloseList}>Talent Match</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <Link className="nav-link" to="/news" onClick={handleCloseList}>News</Link>
                        </li>
                        <li>
                            <Link className="nav-link rounded-0" to="/agenda" onClick={handleCloseList}>Agenda</Link>
                        </li>
                        {/* <li>
                            <Link className="nav-link" to="/speakers" onClick={handleCloseList}>Speakers</Link>
                        </li> */}
                        <li>
                            <Link className="nav-link" to="/traffic-info" onClick={handleCloseList}>Traffic</Link>
                        </li>
                        {!currentUser && (
                        <li>
                            <Link className="nav-link rounded-0" to="/signup" onClick={handleSignUp}>Sign Up</Link>
                        </li>
                        )}
                        {!currentUser && (
                        <li>
                            <Link className="nav-link rounded-0" to="/login" onClick={handleCloseList}>Log In</Link>
                        </li>
                        )}
                        {currentUser && (
                        <li>
                            <Link className="nav-link rounded-0" to="/register" onClick={handleCloseList}>Register</Link>
                        </li>
                        )}
                        {currentUser && (
                        <li className="d-flex align-items-center nav-link position-relative">
                            <div className="cursor-pointer position-relative" onClick={handleToggleAccountList}>
                                <div className="d-flex">
                                    <p>Account</p>
                                    <span className="material-symbols-outlined fs-2 align-self-end">
                                        expand_more
                                    </span>
                                </div>
                                <ul id="accountList" className="hoverBgDarkUl d-none d-flex flex-column flex-wrap position-absolute end-0 bg-white p-2 mt-2" style={{ minWidth: "200%", border: "1px solid rgb(22, 61, 127)", marginRight: "-0.5rem"}}>
                                    <li className="d-flex">
                                        <Link to="/account" className="py-2 px-3 rounded-0 w-100" onClick={handleCloseListAndOffcanvas}>Account Info</Link>
                                    </li>
                                    <li className="d-flex">
                                        <Link to="/account/registration-status" className="py-2 px-3 rounded-0 w-100" onClick={handleCloseList}>Registration Status</Link>
                                    </li>
                                    {isPosterExhibitionRegistration && (
                                        <li className="d-flex">
                                            <Link to="/poster-form" className="py-2 px-3 rounded-0 w-100" onClick={handleCloseList}>Upload Poster</Link>
                                        </li>
                                    )}
                                    
                                    <li className="d-flex">
                                        <button type="button" className="btn py-2 px-3 rounded-0 w-100 text-start" onClick={handleLogout}>Log Out</button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        )}
                    </ul>
                </div>
            </nav>

            <div className="container d-flex d-lg-none justify-content-between align-items-center py-6 h-100">
                <h1 className="fs-2 text-third d-flex">
                    <Link to="/" className="text-nowrap overflow-hidden" style={{background: `url(${API}${logo}) center/contain no-repeat`, width: "124px", height: "45px", textIndent: "101%", overflow: "hidden", whiteSpace: "nowrap"}} onClick={handleCloseList}></Link>
                </h1>
                <button className="btn p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" id="offcanvasBtn">
                    <div className="border-top border-3 border-primary w-8" style={{marginBottom: "0.45rem"}}></div>
                    <div className="border-top border-3 border-primary w-8" style={{marginBottom: "0.45rem"}}></div>
                    <div className="border-top border-3 border-primary w-8"></div>
                </button>
                <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="true" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{width: "375px"}}>
                    <div className="offcanvas-header d-flex justify-content-end p-0">
                        <button type="button" className="btn-close btn me-8 p-4 mt-8" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="hoverBgDarkUl list-unstyled px-4">
                            <li className="d-flex">
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleGoToOtherPages}>Home</button>
                            </li>
                            <li>
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleGoToOtherPages}>About</button>
                            </li>
                            <li>
                                <div className="btn text-start w-100 px-4 py-3" onClick={handleToggleProgramsList}>
                                    <div className="d-flex">
                                        <p>Programs</p>
                                        <span className="material-symbols-outlined fs-6 ms-4">
                                            expand_more
                                        </span>
                                    </div>
                                    <ul id="programsListOffcanvas" className="d-none d-flex flex-column flex-wrap hoverBgDarkUl">
                                        {programs.length > 0 && programs.map((i) =>
                                        <li className="d-flex" key={i.id}>
                                            <Link to={`/programs/${i.id}`} className="py-2 px-3" onClick={handleCloseListAndOffcanvas}>{i.title}</Link>
                                        </li>
                                        )}
                                        <li className="d-flex">
                                            <Link to="/talent-matching" className="py-2 px-3" onClick={handleCloseListAndOffcanvas}>Talent Match</Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="d-flex">
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleGoToOtherPages}>News</button>
                            </li>
                            <li className="d-flex">
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleGoToOtherPages}>Agenda</button>
                            </li>
                            {/* <li className="d-flex">
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleGoToOtherPages}>Speakers</button>
                            </li> */}
                            <li className="d-flex">
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleGoToOtherPages}>Traffic</button>
                            </li>
                            {!currentUser && (
                            <li className="d-flex">
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleSignUpMobile}>Sign Up</button>
                            </li>
                            )}
                            {!currentUser && (
                            <li className="d-flex">
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleGoToOtherPages}>Log In</button>
                            </li>
                            )}
                            {currentUser && (
                            <li className="d-flex">
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleGoToOtherPages}>Register</button>
                            </li>
                            )}
                            {currentUser && (
                            <li className="btn text-start w-100 px-4 py-3">
                                <div className="d-flex" onClick={handleToggleAccountList}>
                                    <p>Account</p>
                                    <span className="material-symbols-outlined fs-6 ms-4">
                                        expand_more
                                    </span>
                                </div>
                                <ul id="accountListOffcanvas" className="d-none d-flex flex-column flex-wrap hoverBgDarkUl">
                                    <li className="d-flex">
                                        <Link to="/account" className="py-2 px-3" onClick={handleCloseListAndOffcanvas}>Account Info</Link>
                                    </li>
                                    <li className="d-flex">
                                        <Link to="/account/registration-status" className="py-2 px-3" onClick={handleCloseListAndOffcanvas}>Registration Status</Link>
                                    </li>
                                    <li className="d-flex">
                                        <Link to="/poster-form" className="py-2 px-3" onClick={handleCloseListAndOffcanvas}>Upload Poster</Link>
                                    </li>
                                </ul>
                            </li>
                            )}
                            {currentUser && (
                            <li className="d-flex">
                                <button type="button" className="btn text-start w-100 px-4 py-3" onClick={handleLogout}>Log Out</button>
                            </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Nav;