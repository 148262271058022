import React, { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate, Link, Routes, Route } from "react-router-dom";
import Swal from "sweetalert2";

import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Loading from "./pages/Loading";
import Homepage from "./pages/Homepage";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import FloatingIcons from "./components/FloatingIcons";

import NewAuthService from "./service/auth-service";
import NewEventService from "./service/event-service";
import NewPosterFormService from "./service/poster-form-service";

import "bootstrap/dist/js/bootstrap";

// Authorization
const Login = lazy(() => import("./pages/Authorization/Login"));
const SignUp = lazy(() => import("./pages/Authorization/SignUp"));
const Verification = lazy(() => import("./pages/Authorization/Verification"));
const ResendVerificationCode = lazy(() => import("./pages/Authorization/ResendVerificationCode"));
const ForgotPassword = lazy(() => import("./pages/Authorization/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/Authorization/ResetPassword"));
const AutoLogin = lazy(() => import("./pages/Authorization/AutoLogin"));

// EventRegistration
const MemberRegister = lazy(() => import("./pages/EventRegistration/MemberRegister"));
const VIPRegister = lazy(() => import("./pages/EventRegistration/VIPRegister"));
const RegisterEnd = lazy(() => import("./pages/EventRegistration/RegisterEnd"));

// Account
const Account = lazy(() => import("./pages/Account/Account"));
const RegistrationStatus = lazy(() => import("./pages/Account/RegistrationStatus"));

const Program = lazy(() => import("./pages/Program"));
const Agenda = lazy(() => import("./pages/Agenda"));
const About = lazy(() => import("./pages/About"));
const Speakers = lazy(() => import("./pages/Speakers"));
const Traffic = lazy(() => import("./pages/Traffic"));

// News
const NewsList = lazy(() => import("./pages/News/List"));
const NewsDetails = lazy(() => import("./pages/News/Details"));

// Poster
const PosterFormCreate = lazy(() => import("./pages/Poster/PosterFormCreate"));
const PosterFormEdit = lazy(() => import("./pages/Poster/PosterFormEdit"));

// TalentMatching
const TalentMatching = lazy(() => import("./pages/TalentMatching/List"));
const TalentMatchingTemp = lazy(() => import("./pages/TalentMatching/Temp")); /* 4/15 前暫時放 */

const TalentMatchingJobSeekingCreate = lazy(() => import("./pages/TalentMatching/JobSeeking/Create"));
const TalentMatchingJobSeekingEdit = lazy(() => import("./pages/TalentMatching/JobSeeking/Edit"));

const TalentMatchingRecruitingCreate = lazy(() => import("./pages/TalentMatching/Recruiting/Create"));
const TalentMatchingRecruitingEdit = lazy(() => import("./pages/TalentMatching/Recruiting/Edit"));

const TalentMatchingJobOpening = lazy(() => import("./pages/TalentMatching/JobOpening/List"));
const TalentMatchingJobOpeningCreate = lazy(() => import("./pages/TalentMatching/JobOpening/Create"));
const TalentMatchingJobOpeningEdit = lazy(() => import("./pages/TalentMatching/JobOpening/Edit"));

const TalentMatchingCompanyDetails = lazy(() => import("./pages/TalentMatching/Details/CompanyDetails"));
const TalentMatchingJobDetails = lazy(() => import("./pages/TalentMatching/Details/JobDetails"));

const BasicAPI = process.env.REACT_APP_BASIC_API;


function App() {
  const Navigate = useNavigate();
  const [ currentUser, setCurrentUser ] = useState(JSON.parse(localStorage.getItem("nbrp_client_user_data")));
  const [ currentEvent, setCurrentEvent ] = useState(null);
  const [ programs, setPrograms ] = useState([]);
  const [ logo, setLogo ] = useState("");
  const [ footer, setFooter ] = useState("");
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);
  const [ isPosterExhibitionRegistration, setIsPosterExhibitionRegistration ] = useState(false);

  const handleCloseMenu = () => setIsMenuOpen(false);

  const handleSignUp = (e) => {
      if (localStorage.getItem("nbrp_client_user_data")) {
          Navigate("/register");
      } else {
          Navigate("/login");
      }
  }

  const handleLinkToMIT = () =>  window.open('https://www.accupass.com/event/2403280844294800916130', '_blank');
  
  useEffect(() => {
    NewEventService.getCurrent()
      .then((d) => {
        const { websiteLogo, websiteIcon, websiteOGIcon, websiteTitle, websiteDescription, dates, footerInfo } = d.data;
        let arr = [];
        dates.forEach((i) => {
            i.sessions.forEach((j) => {
                if (j.isMenuVisible) {
                    arr.push(j);
                }
            });
        });
        arr = arr.sort((a, b) => a.sort - b.sort);
        setPrograms(arr);
        localStorage.setItem("event", JSON.stringify(d.data));
        setCurrentEvent(d.data);
        setLogo(websiteLogo);
        setFooter(footerInfo);

          // 移除現有的 icon
          const existingIcons = document.querySelectorAll('link[rel="icon"]');
          existingIcons.forEach(icon => icon.parentNode.removeChild(icon));

          // 移除現有的 title
          const existingTitles = document.querySelectorAll('title');
          existingTitles.forEach(title => title.parentNode.removeChild(title));

          // 移除現有的 meta description
          const existingMetaDescriptions = document.querySelectorAll('meta[name="description"]');
          existingMetaDescriptions.forEach(meta => meta.parentNode.removeChild(meta));

          // 移除現有的 og:image
          const existingOGIcons = document.querySelectorAll('meta[name="og:image"]');
          existingOGIcons.forEach(meta => meta.parentNode.removeChild(meta));
          const existingMetaOGDescriptions = document.querySelectorAll('meta[name="og:description"]');
          existingMetaOGDescriptions.forEach(meta => meta.parentNode.removeChild(meta));
          const existingMetaOGtitles = document.querySelectorAll('meta[name="og:title"]');
          existingMetaOGtitles.forEach(meta => meta.parentNode.removeChild(meta));

          // 添加新的 icon
          const link = document.createElement('link');
          link.rel = 'icon';
          link.type = 'image/x-icon';
          link.href = BasicAPI + websiteIcon;
          document.head.appendChild(link);

          // 添加新的 title
          const title = document.createElement('title');
          title.innerText = websiteTitle;
          document.head.appendChild(title);

          // 添加新的 meta description
          const metaDescription = document.createElement('meta');
          metaDescription.name = 'description';
          metaDescription.content = websiteDescription;
          document.head.appendChild(metaDescription);

          // 添加新的 og:description
          const metaOGDescription = document.createElement('meta');
          metaOGDescription.name = 'og:description';
          metaOGDescription.content = websiteDescription;
          document.head.appendChild(metaOGDescription);

          // 添加新的 og:title
          const metaOGTitle = document.createElement('meta');
          metaOGTitle.name = 'og:title';
          metaOGTitle.content = websiteTitle;
          document.head.appendChild(metaOGTitle);

          // 添加新的 og:image
          const metaOGIcons = document.createElement('meta');
          metaOGIcons.name = 'og:image';
          metaOGIcons.content = BasicAPI + websiteOGIcon;

          document.head.appendChild(metaOGIcons);
      })

      if(currentUser){
        NewAuthService.getCurrentUser()
        .then((d) => {
          let { email, token, roles, name } = d.data;
          roles = roles[0];
          localStorage.setItem("nbrp_client_user_data", JSON.stringify({ email, token, roles, name }));
          setCurrentUser(d.data);

          NewPosterFormService.get()
            .then((d) => setIsPosterExhibitionRegistration(true))
            .catch((e) => console.log(e))
        })
        .catch((e) => {
            console.log(e)
            NewAuthService.logout();
            localStorage.clear();
            setCurrentUser(null);
            Navigate("/");
        })
      }  
      
  }, []);

  return (
    <div className="min-vh-100 position-relative" onClick={handleCloseMenu}>
      <Nav currentUser={currentUser} setCurrentUser={setCurrentUser} programs={programs} logo={logo} isMenuOpen={isMenuOpen} isPosterExhibitionRegistration={isPosterExhibitionRegistration} setIsPosterExhibitionRegistration={setIsPosterExhibitionRegistration} />
      <Suspense fallback={<Loading />}>
        <Routes>

          <Route exact path="/" element={<Homepage />}></Route>
          <Route exact path="/programs/:id" element={<Program currentEvent={currentEvent} />}></Route>
          <Route exact path="/agenda" element={<Agenda currentEvent={currentEvent} />}></Route>
          <Route exact path="/about" element={<About currentEvent={currentEvent} />}></Route>
          <Route exact path="/news" element={<NewsList />}></Route>
          <Route exact path="/news/:id" element={<NewsDetails />}></Route>
          <Route exact path="/speakers" element={<Speakers currentEvent={currentEvent} />}></Route>
          <Route exact path="/traffic-info" element={<Traffic />}></Route>

          <Route exact path="/poster-form" element={<PosterFormCreate setIsPosterExhibitionRegistration={setIsPosterExhibitionRegistration} />}></Route>
          <Route exact path="/poster-form/edit" element={<PosterFormEdit />}></Route>
          
          <Route exact path="/talent-matching" element={<TalentMatching />}></Route>
          <Route exact path="/talent-match" element={<TalentMatchingTemp />}></Route> {/* 4/15 前暫時放 */}
          <Route exact path="/talent-matching/company/:id" element={<TalentMatchingCompanyDetails />}></Route>

          <Route exact path="/login" element={<Login setCurrentUser={setCurrentUser} setIsPosterExhibitionRegistration={setIsPosterExhibitionRegistration} />}></Route>
          <Route exact path="/signup" element={<SignUp/>}></Route>
          <Route exact path="/verifyEmail/:token" element={<Verification />}></Route>
          <Route exact path="/resend-verification-code" element={<ResendVerificationCode />}></Route>
          <Route exact path="/forgot-password" element={<ForgotPassword/>}></Route>
          <Route exact path="/resetpkey/:token" element={<ResetPassword/>}></Route>
          <Route exact path="/vip-register" element={<VIPRegister />}></Route>
          <Route exact path="/eventautologin/:token" element={<AutoLogin setCurrentUser={setCurrentUser} />}></Route>

          <Route element={<ProtectedRoutes currentUser={currentUser}/>}>
            <Route exact path="/account" element={<Account setCurrentUser={setCurrentUser} />}></Route>
            <Route exact path="/account/registration-status" element={<RegistrationStatus />}></Route>
            <Route exact path="/register" element={<MemberRegister />}></Route>
            <Route exact path="/register-end" element={<RegisterEnd />}></Route>

            <Route exact path="/talent-matching/job-seeking" element={<TalentMatchingJobSeekingCreate />}></Route>
            <Route exact path="/talent-matching/job-seeking/edit" element={<TalentMatchingJobSeekingEdit />}></Route>

            <Route exact path="/talent-matching/company-info" element={<TalentMatchingRecruitingCreate />}></Route>
            <Route exact path="/talent-matching/company-info/edit" element={<TalentMatchingRecruitingEdit />}></Route>

            <Route exact path="/talent-matching/job-opening" element={<TalentMatchingJobOpening />}></Route>
            <Route exact path="/talent-matching/job-opening/create" element={<TalentMatchingJobOpeningCreate />}></Route>
            <Route exact path="/talent-matching/job-opening/:id" element={<TalentMatchingJobOpeningEdit />}></Route>

            <Route exact path="/talent-matching/job-details/:id" element={<TalentMatchingJobDetails />}></Route>
          </Route>
          
        </Routes>
      </Suspense>
      <Footer footer={footer} />

      <FloatingIcons handleSignUp={handleSignUp} handleLinkToMIT={handleLinkToMIT} />
    </div>
  );
}

export default App;
