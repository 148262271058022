export default function FloatingIcons({ handleSignUp, handleLinkToMIT }) {
    return (
        <div className="mb-2 bottom-0 position-fixed end-0 me-0 icons">
            <button type="button"className="btn p-1 rounded-circle d-flex justify-content-center align-items-center flex-cloumn" style={{aspectRatio: "1", background: "#00ffff", fontSize: "12px"}} onClick={handleSignUp}>
                <p>Registration</p>
            </button>
            <button type="button" className="btn p-1 rounded-circle text-white d-flex justify-content-center align-items-center mt-4" style={{aspectRatio: "1", backgroundImage: "linear-gradient(to right bottom, #b6161b, #f944df)", fontSize: "12px"}} onClick={handleLinkToMIT}>
                <p>MIT<br/>Masterclass</p>
            </button>
        </div>
    )
}