import logo from "../assets/images/footer/資產 12 拷貝 2.png";
import bg from "../assets/images/footer/資產 13.png";

const Footer = ({ footer }) => {
    return (
        <footer className="w-100 text-center text-gray py-5" style={{color: "rgb(22, 61, 127)", background: `url(${bg}) center/cover no-repeat`}}>
            <div className="container d-flex flex-column flex-md-row mb-10">
                <div className="w-100 w-md-50 d-flex justify-content-center justify-content-md-end">
                    <img src={logo} alt="" className="img-fluid object-fit-contain" style={{width: "450px"}}></img>
                </div>
                <div className="w-100 w-md-50 footer-html-container ps-md-10 ps-lg-20 mt-5 mt-md-0 d-flex flex-column align-items-center" dangerouslySetInnerHTML={{ __html: footer }}></div>
            </div>
            <small>COPYRIGHT © NBRP 國家生技研究園區 All right reserved. POWER BY <a href="https://effectstudio.com.tw/" target="_blank" className="text-decoration-underline">影響視覺科技</a></small>
        </footer>
    )
}

export default Footer;