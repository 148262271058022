import axios from "axios";
const EventAPI = process.env.REACT_APP_BASIC_API + "/api/Event";

class eventService {
    getCurrent() {
        let token;
        JSON.parse(localStorage.getItem("nbrp_client_user_data"))
        ? token = JSON.parse(localStorage.getItem("nbrp_client_user_data")).token
        : token = "";
        return axios.get(`${EventAPI}/Current`);
    }
}

const NewEventService = new eventService();
export default NewEventService;