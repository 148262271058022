import axios from "axios";
const PosterAPI = process.env.REACT_APP_BASIC_API + "/api/PosterExhibition";
let token;

class posterFormService {
    create(req) {
        JSON.parse(localStorage.getItem("nbrp_client_user_data"))
        ? token =  JSON.parse(localStorage.getItem("nbrp_client_user_data")).token
        : token = "";
        // console.log(token)
        return axios.post(`${PosterAPI}`, 
            req, {
                headers: {Authorization: `Bearer ${token}`}
            });
    }

    get() {
        JSON.parse(localStorage.getItem("nbrp_client_user_data"))
        ? token =  JSON.parse(localStorage.getItem("nbrp_client_user_data")).token
        : token = "";
        // console.log(token)
        return axios.get(`${PosterAPI}`, {
            headers: {Authorization: `Bearer ${token}`}
        });
    }

    update(req) {
        JSON.parse(localStorage.getItem("nbrp_client_user_data"))
        ? token =  JSON.parse(localStorage.getItem("nbrp_client_user_data")).token
        : token = "";
        // console.log(token)
        return axios.put(`${PosterAPI}`, 
            req, {
                headers: {Authorization: `Bearer ${token}`}
            });
    }

}

const NewPosterFormService = new posterFormService();
export default NewPosterFormService;